import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 500">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
					<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M249.000000,501.000000 
	C166.000015,501.000000 83.500038,501.000000 1.000044,501.000000 
	C1.000030,334.333405 1.000030,167.666779 1.000015,1.000134 
	C167.666565,1.000089 334.333130,1.000089 500.999756,1.000045 
	C500.999847,167.666519 500.999847,334.333038 500.999939,500.999786 
	C417.166656,501.000000 333.333344,501.000000 249.000000,501.000000 
M120.658257,281.821289 
	C120.816856,281.253601 120.975449,280.685883 121.690598,279.710083 
	C123.784615,275.080017 125.878632,270.449951 128.499878,265.451782 
	C128.680054,264.638519 128.860229,263.825287 129.242996,262.719543 
	C129.242996,262.719543 129.127182,262.383118 129.755737,262.098114 
	C130.173264,261.066833 130.590805,260.035522 131.202759,258.687744 
	C131.202759,258.687744 131.126862,258.324127 131.740891,258.037994 
	C132.656418,255.713776 133.571945,253.389557 135.272507,250.794174 
	C136.151474,248.848038 137.030441,246.901917 138.395782,244.548569 
	C138.661331,243.756958 138.926880,242.965347 139.736115,241.753342 
	C141.824036,237.177353 143.911957,232.601364 146.529938,227.593521 
	C147.316605,225.683823 148.103287,223.774124 149.270508,221.332977 
	C149.876801,219.916077 150.483109,218.499176 151.648117,216.727829 
	C152.107941,215.494308 152.567749,214.260773 153.581161,212.618011 
	C155.034164,208.750473 156.487183,204.882919 158.050095,200.390411 
	C158.016129,200.181625 157.982162,199.972855 158.377182,199.377853 
	C162.004456,187.130829 153.973434,179.177948 146.386307,176.252167 
	C137.888062,172.975021 124.815277,177.407059 121.156990,188.343048 
	C118.039185,195.201477 114.921387,202.059906 111.236961,209.248764 
	C110.794830,210.479172 110.352699,211.709579 109.360298,213.375412 
	C107.543312,217.233047 105.726326,221.090668 103.276733,225.261337 
	C102.858231,226.605484 102.439735,227.949646 101.567627,229.760956 
	C100.365623,232.493362 99.163612,235.225784 97.416733,238.356094 
	C96.264915,240.277496 95.113098,242.198898 93.695503,243.458221 
	C91.886848,239.910065 90.078194,236.361893 88.221130,232.828033 
	C88.221130,232.828033 88.227844,232.876495 88.075775,232.325836 
	C87.739960,232.160446 87.404144,231.995041 87.075455,231.104874 
	C86.623795,229.770584 86.172134,228.436310 85.603203,226.429199 
	C84.129898,223.898560 82.656586,221.367920 81.184601,218.174454 
	C80.825905,217.666168 80.467209,217.157883 80.243690,215.912842 
	C77.899239,210.985123 75.554787,206.057404 73.167839,200.462875 
	C70.584961,195.321701 68.002083,190.180511 65.406754,184.415039 
	C59.923115,176.317673 51.155983,173.134140 42.451473,176.079453 
	C34.106964,178.902969 28.806856,186.456451 29.003204,195.754700 
	C29.100779,196.484634 29.198353,197.214554 29.216314,198.762985 
	C29.500942,199.515305 29.785570,200.267624 29.904497,201.642258 
	C33.304485,208.669266 36.704472,215.696259 40.174068,223.467453 
	C42.820549,228.868912 45.467030,234.270355 48.078609,240.280777 
	C48.348709,240.555099 48.618813,240.829422 48.950516,241.720352 
	C49.319012,242.437943 49.687508,243.155518 50.017975,244.446716 
	C50.279621,244.698639 50.541267,244.950577 50.847321,245.018509 
	C50.847321,245.018509 50.756168,245.184387 50.822968,245.824738 
	C51.257256,246.544067 51.691540,247.263397 52.151424,248.708023 
	C55.215519,255.109360 58.279610,261.510712 61.391224,268.438202 
	C61.773613,269.018402 62.156002,269.598602 62.673534,270.837799 
	C63.112682,271.589600 63.551834,272.341431 64.033005,273.700928 
	C64.298904,273.989410 64.564812,274.277863 64.748207,274.820862 
	C64.748207,274.820862 64.952652,274.993500 64.999207,275.738922 
	C67.561630,280.531952 70.124054,285.325012 72.740196,290.814514 
	C74.833778,295.170715 76.927353,299.526886 79.130112,304.568451 
	C84.112572,309.437744 89.528732,313.091400 97.777931,311.073486 
	C100.164810,310.053680 102.551697,309.033875 105.010658,308.014954 
	C105.010658,308.014954 105.017250,307.943207 105.625854,307.882385 
	C106.153488,307.320343 106.681114,306.758301 107.033134,306.124542 
	C107.033134,306.124542 107.180733,306.243683 107.679359,306.243591 
	C107.679359,306.243591 107.988884,305.852661 108.485847,305.777374 
	C108.652725,305.503082 108.819603,305.228821 109.624458,304.714752 
	C113.001671,297.498505 116.378891,290.282227 120.093796,282.661072 
	C120.111664,282.486237 120.129532,282.311371 120.658257,281.821289 
M275.000000,307.000000 
	C275.000000,307.000000 274.997131,306.998901 275.771942,306.849396 
	C276.290955,304.467010 276.809967,302.084656 277.321167,299.738068 
	C274.642639,298.971741 272.898254,298.472656 270.989655,297.413116 
	C270.947723,297.026581 270.905823,296.640076 270.929077,295.503540 
	C270.470703,294.794464 270.012299,294.085388 269.553925,293.376312 
	C267.917908,294.632538 266.281891,295.888733 263.987366,297.650543 
	C263.576080,297.603302 262.147308,297.439117 260.675110,297.314148 
	C260.675110,297.314148 260.629669,297.351013 260.833923,296.532990 
	C259.640869,295.358856 258.421387,293.195770 257.260498,293.226715 
	C255.165146,293.282654 252.054871,294.111237 251.288361,295.603363 
	C250.267792,297.590057 246.653534,300.386719 251.046021,303.603363 
	C251.386734,304.352478 251.727448,305.101562 252.036560,306.625549 
	C253.587387,310.856720 255.775436,309.183960 258.584076,307.013031 
	C259.569244,305.942169 260.554382,304.871307 262.347504,303.906494 
	C264.217560,304.605774 266.087646,305.305023 268.231506,306.447937 
	C268.480469,306.689667 268.729401,306.931366 269.163239,307.877136 
	C271.358154,309.239990 273.526520,310.426575 275.000000,307.000000 
M210.998917,305.000580 
	C210.998917,305.000580 210.987625,305.011169 210.928436,304.251770 
	C210.849945,302.532166 210.679428,300.804352 210.831329,299.105347 
	C210.846985,298.930115 213.095306,298.483276 213.758575,298.947296 
	C214.759888,299.647766 215.791199,300.983246 215.918549,302.146393 
	C216.827408,310.448029 217.999283,311.128174 225.568054,307.075745 
	C228.979889,303.513885 226.798645,299.714539 225.783676,295.787689 
	C224.828766,297.126129 223.873840,298.464569 223.270493,299.310242 
	C221.189774,298.414612 219.549271,297.708466 217.161880,296.981689 
	C214.729401,295.835480 212.345261,294.568634 209.853485,293.570435 
	C205.769897,291.934570 202.412506,294.702179 203.038803,299.699066 
	C202.955933,300.136597 202.873077,300.574127 202.408722,301.330139 
	C202.548386,301.884430 202.688049,302.438690 202.974503,303.498962 
	C202.979858,303.674500 202.985229,303.850037 203.066391,304.408051 
	C203.168793,304.659943 203.340805,304.848236 204.002182,305.489960 
	C204.673019,306.056946 205.343872,306.623901 206.414734,307.666473 
	C207.381271,308.247589 208.440613,309.408691 209.292389,309.272400 
	C210.535934,309.073456 211.630386,307.942566 212.788391,307.208954 
	C212.188370,306.476593 211.588348,305.744263 210.998917,305.000580 
M307.039246,300.117126 
	C306.058838,294.363556 304.155060,293.437286 299.458679,297.271149 
	C299.410278,300.148987 298.779633,303.210968 299.602539,305.813263 
	C299.927734,306.841705 304.277649,307.885223 305.374390,307.052002 
	C306.710480,306.036987 306.549347,303.051270 307.039246,300.117126 
M292.454102,303.909729 
	C295.472534,302.778015 295.803101,301.178070 293.588013,298.770447 
	C292.195984,297.257416 291.147644,295.428192 289.867401,293.631836 
	C287.935822,293.747955 285.632782,293.805634 283.348999,294.046173 
	C281.001099,294.293457 280.257416,295.257202 282.083801,297.850128 
	C280.924805,301.216492 279.878448,304.786926 283.969696,306.776978 
	C287.158142,308.327942 290.020233,307.342957 292.454102,303.909729 
M386.000000,249.000000 
	C386.000000,249.000000 385.998779,248.999237 385.998657,248.150330 
	C386.673981,242.901657 387.477478,237.664948 387.968506,232.399094 
	C388.200226,229.913681 388.952362,226.599365 384.457062,227.046280 
	C380.731750,227.416641 375.877441,225.078552 375.023254,231.788284 
	C372.872101,248.685699 370.367615,265.538177 368.235352,282.437775 
	C368.084625,283.632385 369.690247,286.103088 370.690399,286.223328 
	C379.523529,287.285217 388.436676,288.730774 397.256409,288.367889 
	C401.087158,288.210266 406.127045,284.945435 408.214050,281.594727 
	C412.705139,274.384186 414.467590,265.858795 413.876129,257.255005 
	C413.114838,246.180893 406.109528,241.732101 395.609497,245.277267 
	C392.358246,246.375000 389.201324,247.752045 386.000000,249.000000 
M394.153992,143.261093 
	C386.925293,142.103928 379.696625,140.946747 372.283752,139.760101 
	C372.283752,144.977188 372.283752,148.738144 372.283752,152.441513 
	C414.226105,157.013641 437.028015,180.377823 441.528992,221.749969 
	C445.622711,221.749969 449.377472,221.749969 453.103912,221.749969 
	C454.931274,193.805695 435.357635,155.285751 394.153992,143.261093 
M284.855804,244.530472 
	C285.022308,240.096619 287.454346,238.493500 291.716370,238.617188 
	C297.707977,238.791077 303.713654,238.516815 309.702545,238.732773 
	C313.201447,238.858917 313.378174,236.498016 313.618347,234.219910 
	C313.844604,232.073456 314.931885,229.417435 311.310486,228.717377 
	C300.920227,226.708862 290.533112,225.668259 280.266876,229.210571 
	C273.524445,231.537048 272.006653,237.511902 271.245605,243.558838 
	C270.485565,249.597427 271.327728,255.484283 277.443970,258.655823 
	C282.297791,261.172729 287.443512,263.143646 292.520447,265.207306 
	C295.624603,266.469055 297.684967,268.231232 297.028992,271.922089 
	C296.412659,275.389740 294.308807,277.135162 290.821838,277.176239 
	C285.994385,277.233124 281.166229,277.266815 276.338531,277.252319 
	C267.621216,277.226135 267.616791,277.207550 266.438568,285.547424 
	C266.398682,285.829681 266.645874,286.152466 266.940125,286.940918 
	C269.250793,287.396088 271.801666,288.193054 274.391296,288.349792 
	C280.531830,288.721558 286.690521,288.944000 292.842163,288.950378 
	C308.558685,288.966614 312.268158,275.771057 310.041321,264.200989 
	C309.236633,260.019867 306.541565,257.115204 302.489899,255.558319 
	C298.296875,253.947128 293.967896,252.585495 290.003204,250.527466 
	C287.945068,249.459076 286.545319,247.122269 284.855804,244.530472 
M231.576553,288.307343 
	C239.275177,287.945648 246.989655,287.755371 254.656250,287.046997 
	C255.878967,286.934052 257.653442,284.598602 257.852509,283.113647 
	C258.487823,278.374573 256.522003,277.386902 250.335510,278.084351 
	C246.272812,278.542358 242.076630,278.844269 238.067764,278.291321 
	C236.231003,278.037994 234.701248,275.558990 233.032272,274.089294 
	C234.997772,273.061981 236.862625,271.512268 238.948608,271.110443 
	C244.113983,270.115479 249.514359,270.154327 254.569458,268.824341 
	C259.824677,267.441650 263.217316,261.296112 262.951935,255.559677 
	C262.714111,250.417542 259.225739,245.828888 253.239059,245.205276 
	C246.614166,244.515182 239.785095,244.849228 233.137863,245.581131 
	C226.887405,246.269333 223.234131,251.051193 221.467102,256.586548 
	C219.980255,261.244263 219.286591,266.297638 219.123993,271.205109 
	C218.814316,280.551086 221.943176,284.669495 231.576553,288.307343 
M210.438766,247.098022 
	C207.317902,243.903214 203.329712,243.152893 199.415009,244.461578 
	C194.016556,246.266251 188.871368,248.828430 183.391296,251.162567 
	C183.346039,251.016876 182.954315,250.410767 183.017624,249.856644 
	C183.568207,245.036102 180.505661,245.045074 177.026520,244.957993 
	C173.436325,244.868149 172.142883,246.083176 171.874008,249.795593 
	C171.445572,255.710617 170.181580,261.561798 169.363297,267.452789 
	C168.443069,274.077484 167.625351,280.716431 166.720718,287.674652 
	C171.582306,287.674652 175.477295,287.674652 179.577133,287.674652 
	C180.414230,281.345856 181.428894,275.328674 181.954697,269.269073 
	C182.573883,262.133270 187.115997,256.564423 194.164032,255.153687 
	C197.226959,254.540619 199.519882,255.073257 199.033005,259.076599 
	C198.474152,263.671661 198.040466,268.281799 197.522186,272.881958 
	C196.977173,277.719421 196.396423,282.552826 195.795639,287.693085 
	C198.131531,287.833466 199.593613,287.981873 201.057236,287.999084 
	C208.382782,288.085266 208.230576,288.055573 209.071671,280.777954 
	C209.963287,273.063293 211.448105,265.410126 212.135422,257.682220 
	C212.424164,254.435577 211.330063,251.065948 210.438766,247.098022 
M361.665802,245.465790 
	C358.857178,245.327789 356.013336,244.881729 353.256592,245.197189 
	C352.086243,245.331116 350.388153,246.918823 350.152008,248.091843 
	C349.175262,252.943558 348.422394,257.870789 348.045563,262.805298 
	C347.550720,269.285034 345.804474,274.904144 339.047058,277.178040 
	C336.846619,277.918518 334.118408,277.907806 331.899353,277.285522 
	C331.220154,277.095062 331.156586,273.702911 331.305511,271.815979 
	C331.499237,269.361359 332.320953,266.962921 332.634430,264.509888 
	C333.439911,258.206390 334.114136,251.886124 334.863312,245.377106 
	C330.342621,245.377106 326.446167,245.377106 322.491272,245.377106 
	C322.117950,247.486374 321.723236,249.254440 321.501923,251.043976 
	C320.305389,260.718628 318.891266,270.376984 318.086151,280.085236 
	C317.639587,285.469849 320.238892,288.199158 325.499054,288.872101 
	C331.868378,289.686890 337.725891,288.350861 342.631378,283.886932 
	C343.735474,282.882202 344.962280,282.012360 346.619934,280.692352 
	C346.964050,283.632782 347.189453,285.558807 347.404114,287.393036 
	C356.929199,289.059418 356.999512,288.932678 358.092682,280.257233 
	C358.753174,275.015839 359.932251,269.839081 360.571564,264.595886 
	C361.324127,258.423767 361.744751,252.211197 361.665802,245.465790 
M407.773315,178.741608 
	C397.503204,170.446976 385.956818,165.802155 372.281006,166.007904 
	C372.281006,170.473282 372.281006,174.516525 372.281006,178.507416 
	C399.363647,182.579910 408.564697,191.716629 415.924164,221.785217 
	C419.740265,221.785217 423.632355,221.785217 428.064209,221.785217 
	C427.819366,204.363174 421.119934,190.395584 407.773315,178.741608 
M159.970520,261.413788 
	C160.313980,258.458557 160.792419,255.511597 160.974518,252.546463 
	C161.441132,244.947983 161.369934,245.292908 153.846588,244.892548 
	C149.623215,244.667770 148.675278,246.878098 148.218277,249.976990 
	C147.447006,255.206741 146.901642,260.469513 146.233109,265.714752 
	C145.304474,273.000702 144.349808,280.283295 143.350403,287.998718 
	C146.454193,287.998718 148.963974,287.735107 151.396027,288.057556 
	C155.526596,288.605316 156.521393,286.200958 156.979691,282.912384 
	C157.937607,276.038361 158.961761,269.173523 159.970520,261.413788 
M152.544815,238.233627 
	C154.984863,238.437347 157.530533,239.147293 159.828033,238.667908 
	C161.375488,238.345016 163.517105,236.477386 163.720062,235.061111 
	C164.088409,232.490631 163.848022,227.805466 162.729507,227.436646 
	C159.608734,226.407623 155.670410,226.392776 152.511627,227.404190 
	C149.946716,228.225449 149.889923,235.074280 152.544815,238.233627 
M239.561813,298.643585 
	C238.128159,297.760895 236.694519,296.878235 235.260880,295.995544 
	C234.859360,297.644653 234.212723,299.280060 234.119614,300.946411 
	C234.008194,302.940521 234.369720,304.961090 234.450577,305.997772 
	C238.284180,306.298645 241.146408,306.523285 243.628128,306.718048 
	C243.628128,303.026215 243.628128,299.481934 243.628128,294.910492 
	C241.968613,296.803619 241.157196,297.729248 239.561813,298.643585 
M190.080933,301.569489 
	C190.080933,303.309265 190.080933,305.049042 190.080933,306.916504 
	C192.651779,306.916504 194.716034,306.916504 196.550934,306.916504 
	C196.550934,303.164429 196.550934,299.755402 196.550934,296.150970 
	C194.369370,296.150970 192.309494,296.150970 190.080185,296.150970 
	C190.080185,297.927521 190.080185,299.375519 190.080933,301.569489 
M339.017242,296.201019 
	C334.685425,298.807434 337.929688,301.039124 339.447968,303.304871 
	C338.500549,304.304382 337.617249,305.236267 336.574554,306.336273 
	C343.061005,308.253479 344.479980,307.056610 343.345428,301.199097 
	C343.223206,300.568024 343.028595,299.836731 343.224518,299.276825 
	C344.513184,295.594360 342.024811,295.971558 339.017242,296.201019 
M327.929901,303.155396 
	C328.743317,300.914703 329.556732,298.673981 330.411896,296.318237 
	C328.120422,296.318237 326.247803,296.318237 324.281677,296.318237 
	C324.281677,300.088135 324.281677,303.462067 324.281677,307.251099 
	C326.626526,307.068298 328.539734,306.919159 331.457489,306.691711 
	C329.409241,305.118622 328.415558,304.355469 327.929901,303.155396 
M316.997711,301.514008 
	C316.736115,299.676544 316.474518,297.839081 316.212921,296.001617 
	C315.708801,296.071198 315.204681,296.140747 314.700562,296.210327 
	C314.700562,299.771881 314.700562,303.333435 314.700562,306.894989 
	C315.218842,306.955536 315.737122,307.016052 316.255371,307.076599 
	C316.504242,305.520264 316.753082,303.963959 316.997711,301.514008 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M386.001221,249.001221 
	C389.201324,247.752045 392.358246,246.375000 395.609497,245.277267 
	C406.109528,241.732101 413.114838,246.180893 413.876129,257.255005 
	C414.467590,265.858795 412.705139,274.384186 408.214050,281.594727 
	C406.127045,284.945435 401.087158,288.210266 397.256409,288.367889 
	C388.436676,288.730774 379.523529,287.285217 370.690399,286.223328 
	C369.690247,286.103088 368.084625,283.632385 368.235352,282.437775 
	C370.367615,265.538177 372.872101,248.685699 375.023254,231.788284 
	C375.877441,225.078552 380.731750,227.416641 384.457062,227.046280 
	C388.952362,226.599365 388.200226,229.913681 387.968506,232.399094 
	C387.477478,237.664948 386.673981,242.901657 385.789032,248.695251 
	C385.604462,249.597885 385.629517,249.955612 385.654602,250.313339 
	C385.770111,249.875961 385.885651,249.438583 386.001221,249.001221 
M393.279541,255.386093 
	C387.970490,257.174011 383.760132,259.980011 383.139954,266.185455 
	C382.812073,269.466309 382.227936,272.736572 382.157166,276.020233 
	C382.130951,277.236511 382.923706,279.126160 383.887939,279.600159 
	C389.572815,282.394714 396.927246,278.943970 398.572632,272.717834 
	C399.452759,269.387573 399.700928,265.894958 400.343750,262.496307 
	C401.589874,255.907990 400.727753,254.863892 393.279541,255.386093 
z"/>
<path fill="#F99455" opacity="1.000000" stroke="none" 
	d="
M394.530701,143.404510 
	C435.357635,155.285751 454.931274,193.805695 453.103912,221.749969 
	C449.377472,221.749969 445.622711,221.749969 441.528992,221.749969 
	C437.028015,180.377823 414.226105,157.013641 372.283752,152.441513 
	C372.283752,148.738144 372.283752,144.977188 372.283752,139.760101 
	C379.696625,140.946747 386.925293,142.103928 394.530701,143.404510 
z"/>
<path fill="#F99557" opacity="1.000000" stroke="none" 
	d="
M284.851990,244.944458 
	C286.545319,247.122269 287.945068,249.459076 290.003204,250.527466 
	C293.967896,252.585495 298.296875,253.947128 302.489899,255.558319 
	C306.541565,257.115204 309.236633,260.019867 310.041321,264.200989 
	C312.268158,275.771057 308.558685,288.966614 292.842163,288.950378 
	C286.690521,288.944000 280.531830,288.721558 274.391296,288.349792 
	C271.801666,288.193054 269.250793,287.396088 266.940125,286.940918 
	C266.645874,286.152466 266.398682,285.829681 266.438568,285.547424 
	C267.616791,277.207550 267.621216,277.226135 276.338531,277.252319 
	C281.166229,277.266815 285.994385,277.233124 290.821838,277.176239 
	C294.308807,277.135162 296.412659,275.389740 297.028992,271.922089 
	C297.684967,268.231232 295.624603,266.469055 292.520447,265.207306 
	C287.443512,263.143646 282.297791,261.172729 277.443970,258.655823 
	C271.327728,255.484283 270.485565,249.597427 271.245605,243.558838 
	C272.006653,237.511902 273.524445,231.537048 280.266876,229.210571 
	C290.533112,225.668259 300.920227,226.708862 311.310486,228.717377 
	C314.931885,229.417435 313.844604,232.073456 313.618347,234.219910 
	C313.378174,236.498016 313.201447,238.858917 309.702545,238.732773 
	C303.713654,238.516815 297.707977,238.791077 291.716370,238.617188 
	C287.454346,238.493500 285.022308,240.096619 284.851990,244.944458 
M292.371582,230.053040 
	C290.207825,231.923141 288.044067,233.793243 285.880341,235.663345 
	C291.368927,236.085388 296.084412,235.699799 300.730164,234.906036 
	C302.188293,234.656921 303.458893,233.310242 304.815308,232.465942 
	C303.345306,231.646500 301.947601,230.357574 300.388397,230.117477 
	C298.039612,229.755768 295.577881,230.127213 292.371582,230.053040 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M231.182327,288.189819 
	C221.943176,284.669495 218.814316,280.551086 219.123993,271.205109 
	C219.286591,266.297638 219.980255,261.244263 221.467102,256.586548 
	C223.234131,251.051193 226.887405,246.269333 233.137863,245.581131 
	C239.785095,244.849228 246.614166,244.515182 253.239059,245.205276 
	C259.225739,245.828888 262.714111,250.417542 262.951935,255.559677 
	C263.217316,261.296112 259.824677,267.441650 254.569458,268.824341 
	C249.514359,270.154327 244.113983,270.115479 238.948608,271.110443 
	C236.862625,271.512268 234.997772,273.061981 233.032272,274.089294 
	C234.701248,275.558990 236.231003,278.037994 238.067764,278.291321 
	C242.076630,278.844269 246.272812,278.542358 250.335510,278.084351 
	C256.522003,277.386902 258.487823,278.374573 257.852509,283.113647 
	C257.653442,284.598602 255.878967,286.934052 254.656250,287.046997 
	C246.989655,287.755371 239.275177,287.945648 231.182327,288.189819 
M244.427246,253.961548 
	C236.655212,253.996628 234.773392,255.645233 233.493698,264.188782 
	C238.024033,263.635895 242.488312,263.537231 246.639923,262.347565 
	C248.137466,261.918427 249.458969,258.940399 249.699081,256.983032 
	C249.799042,256.168182 246.863998,254.981018 244.427246,253.961548 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M210.651703,247.425125 
	C211.330063,251.065948 212.424164,254.435577 212.135422,257.682220 
	C211.448105,265.410126 209.963287,273.063293 209.071671,280.777954 
	C208.230576,288.055573 208.382782,288.085266 201.057236,287.999084 
	C199.593613,287.981873 198.131531,287.833466 195.795639,287.693085 
	C196.396423,282.552826 196.977173,277.719421 197.522186,272.881958 
	C198.040466,268.281799 198.474152,263.671661 199.033005,259.076599 
	C199.519882,255.073257 197.226959,254.540619 194.164032,255.153687 
	C187.115997,256.564423 182.573883,262.133270 181.954697,269.269073 
	C181.428894,275.328674 180.414230,281.345856 179.577133,287.674652 
	C175.477295,287.674652 171.582306,287.674652 166.720718,287.674652 
	C167.625351,280.716431 168.443069,274.077484 169.363297,267.452789 
	C170.181580,261.561798 171.445572,255.710617 171.874008,249.795593 
	C172.142883,246.083176 173.436325,244.868149 177.026520,244.957993 
	C180.505661,245.045074 183.568207,245.036102 183.017624,249.856644 
	C182.954315,250.410767 183.346039,251.016876 183.391296,251.162567 
	C188.871368,248.828430 194.016556,246.266251 199.415009,244.461578 
	C203.329712,243.152893 207.317902,243.903214 210.651703,247.425125 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M361.987549,245.740967 
	C361.744751,252.211197 361.324127,258.423767 360.571564,264.595886 
	C359.932251,269.839081 358.753174,275.015839 358.092682,280.257233 
	C356.999512,288.932678 356.929199,289.059418 347.404114,287.393036 
	C347.189453,285.558807 346.964050,283.632782 346.619934,280.692352 
	C344.962280,282.012360 343.735474,282.882202 342.631378,283.886932 
	C337.725891,288.350861 331.868378,289.686890 325.499054,288.872101 
	C320.238892,288.199158 317.639587,285.469849 318.086151,280.085236 
	C318.891266,270.376984 320.305389,260.718628 321.501923,251.043976 
	C321.723236,249.254440 322.117950,247.486374 322.491272,245.377106 
	C326.446167,245.377106 330.342621,245.377106 334.863312,245.377106 
	C334.114136,251.886124 333.439911,258.206390 332.634430,264.509888 
	C332.320953,266.962921 331.499237,269.361359 331.305511,271.815979 
	C331.156586,273.702911 331.220154,277.095062 331.899353,277.285522 
	C334.118408,277.907806 336.846619,277.918518 339.047058,277.178040 
	C345.804474,274.904144 347.550720,269.285034 348.045563,262.805298 
	C348.422394,257.870789 349.175262,252.943558 350.152008,248.091843 
	C350.388153,246.918823 352.086243,245.331116 353.256592,245.197189 
	C356.013336,244.881729 358.857178,245.327789 361.987549,245.740967 
z"/>
<path fill="#3FB433" opacity="1.000000" stroke="none" 
	d="
M408.043457,178.971420 
	C421.119934,190.395584 427.819366,204.363174 428.064209,221.785217 
	C423.632355,221.785217 419.740265,221.785217 415.924164,221.785217 
	C408.564697,191.716629 399.363647,182.579910 372.281006,178.507416 
	C372.281006,174.516525 372.281006,170.473282 372.281006,166.007904 
	C385.956818,165.802155 397.503204,170.446976 408.043457,178.971420 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M159.964615,261.859375 
	C158.961761,269.173523 157.937607,276.038361 156.979691,282.912384 
	C156.521393,286.200958 155.526596,288.605316 151.396027,288.057556 
	C148.963974,287.735107 146.454193,287.998718 143.350403,287.998718 
	C144.349808,280.283295 145.304474,273.000702 146.233109,265.714752 
	C146.901642,260.469513 147.447006,255.206741 148.218277,249.976990 
	C148.675278,246.878098 149.623215,244.667770 153.846588,244.892548 
	C161.369934,245.292908 161.441132,244.947983 160.974518,252.546463 
	C160.792419,255.511597 160.313980,258.458557 159.964615,261.859375 
z"/>
<path fill="#0AA7FD" opacity="1.000000" stroke="none" 
	d="
M127.972656,265.819885 
	C125.878632,270.449951 123.784615,275.080017 121.364365,279.873413 
	C121.038139,280.036774 120.938644,279.959808 121.001099,279.586792 
	C122.019653,275.959412 119.740250,269.102600 117.529572,269.352570 
	C116.164299,269.506897 115.039604,271.789185 114.018875,272.888000 
	C112.692169,271.743561 111.312790,269.865509 109.472542,269.109894 
	C105.674034,267.550293 101.630035,265.635559 97.686584,265.634583 
	C93.227379,265.633453 88.089340,262.022400 84.117043,266.683380 
	C85.386513,263.554321 86.648499,260.783417 88.051384,257.800537 
	C88.466690,257.394348 88.741089,257.200134 89.334358,256.834473 
	C91.433556,255.830750 93.287292,254.155701 94.982277,254.303345 
	C105.803864,255.245834 115.516998,261.445801 126.658043,261.211456 
	C127.105110,261.202057 127.577164,262.380981 128.025208,263.358612 
	C127.999458,264.413971 127.986053,265.116913 127.972656,265.819885 
z"/>
<path fill="#09D9FB" opacity="1.000000" stroke="none" 
	d="
M119.756104,283.065979 
	C116.378891,290.282227 113.001671,297.498505 109.305824,304.420258 
	C108.987190,302.550720 108.987190,300.975708 108.987190,299.174469 
	C106.922272,299.332245 105.686867,299.426636 105.518608,299.439484 
	C103.750069,297.536682 102.629692,296.331238 101.509323,295.125793 
	C100.294495,296.454041 99.079659,297.782257 98.344818,298.585693 
	C94.387550,297.370331 90.391907,296.151764 86.402084,294.914459 
	C84.083679,294.195496 80.540840,294.099396 79.730705,292.568451 
	C78.264862,289.798431 77.186623,292.199371 76.019623,291.577850 
	C76.294113,288.366760 76.585732,285.582062 77.004333,282.552856 
	C77.469406,281.879578 77.807487,281.450775 78.521698,281.067932 
	C88.675156,283.824615 98.744537,285.800507 108.118484,289.526184 
	C113.598503,291.704132 115.044670,289.361755 117.049515,285.765076 
	C117.590347,284.794800 118.357185,283.950531 119.204376,283.047180 
	C119.388466,283.045959 119.756104,283.065979 119.756104,283.065979 
z"/>
<path fill="#08ECFB" opacity="1.000000" stroke="none" 
	d="
M76.036743,292.004242 
	C77.186623,292.199371 78.264862,289.798431 79.730705,292.568451 
	C80.540840,294.099396 84.083679,294.195496 86.402084,294.914459 
	C90.391907,296.151764 94.387550,297.370331 98.344818,298.585693 
	C99.079659,297.782257 100.294495,296.454041 101.509323,295.125793 
	C102.629692,296.331238 103.750069,297.536682 105.518608,299.439484 
	C105.686867,299.426636 106.922272,299.332245 108.987190,299.174469 
	C108.987190,300.975708 108.987190,302.550720 108.986832,304.540161 
	C108.819603,305.228821 108.652725,305.503082 108.111557,305.672668 
	C107.551758,305.793213 107.366241,306.018433 107.180725,306.243683 
	C107.180733,306.243683 107.033134,306.124542 106.783401,306.123108 
	C106.028198,306.728851 105.522720,307.336029 105.017250,307.943207 
	C105.017250,307.943207 105.010658,308.014954 104.601974,308.010254 
	C101.799835,308.986542 99.406387,309.967499 97.012932,310.948486 
	C89.528732,313.091400 84.112572,309.437744 79.062653,303.855957 
	C78.009041,299.430420 77.022896,295.717316 76.036743,292.004242 
z"/>
<path fill="#0F65FA" opacity="1.000000" stroke="none" 
	d="
M61.343700,267.912048 
	C58.279610,261.510712 55.215519,255.109360 52.340195,248.352783 
	C52.528969,247.997528 52.932331,248.006851 53.100227,248.245651 
	C53.842911,248.988373 54.417694,249.492294 54.826225,250.085968 
	C54.775879,250.443573 54.891785,250.711411 55.226692,251.091705 
	C55.718697,251.407684 55.905842,251.672028 55.813614,252.200546 
	C56.746113,254.226883 57.872128,256.049835 58.998142,257.872803 
	C59.436386,257.676208 59.874634,257.479614 60.312881,257.283020 
	C60.692005,255.750656 61.071129,254.218277 61.426479,252.781998 
	C62.664574,252.495255 64.034737,252.518616 64.991875,251.901428 
	C71.604591,247.637329 77.954079,242.929947 84.769859,239.032562 
	C87.623856,237.400589 88.181023,235.490494 88.269539,232.813736 
	C90.078194,236.361893 91.886848,239.910065 93.831680,244.144424 
	C93.959473,245.539627 93.951073,246.248627 93.707001,247.121826 
	C92.986832,247.860886 92.502357,248.435745 91.744507,248.823227 
	C88.863060,247.911743 86.254997,247.187622 83.395264,246.393631 
	C83.134682,248.139603 82.899338,249.716476 82.821938,250.235107 
	C75.035889,256.282196 65.178131,258.265869 61.343700,267.912048 
z"/>
<path fill="#1331F9" opacity="1.000000" stroke="none" 
	d="
M157.940201,201.015381 
	C156.487183,204.882919 155.034164,208.750473 153.273987,212.792648 
	C152.966797,212.967285 152.906036,212.907318 152.939865,212.502472 
	C152.913010,206.020447 151.177368,204.683182 145.585678,203.735611 
	C139.461884,202.697830 133.364914,201.025513 127.533447,198.877335 
	C122.056221,196.859650 120.482643,196.943771 117.444603,201.853714 
	C116.754501,202.969009 115.849373,203.951263 114.905403,205.285583 
	C114.167183,206.715439 113.566788,207.854462 112.675552,208.976868 
	C112.190994,208.946274 111.997284,208.932297 111.803581,208.918335 
	C114.921387,202.059906 118.039185,195.201477 121.723877,188.068939 
	C122.862297,187.527267 123.433815,187.259735 124.401688,187.009201 
	C125.944054,188.545441 127.090088,190.064651 128.147888,191.466919 
	C136.853455,193.474411 145.604172,195.492325 153.583298,197.332321 
	C155.268387,198.756790 156.604294,199.886078 157.940201,201.015381 
z"/>
<path fill="#0E9DF9" opacity="1.000000" stroke="none" 
	d="
M73.210335,201.129684 
	C75.554787,206.057404 77.899239,210.985123 80.142639,216.672668 
	C80.024239,217.954605 80.006889,218.476685 79.624237,219.001724 
	C77.694809,219.018143 76.115982,218.904282 74.570938,219.082825 
	C73.173027,219.244339 71.165939,219.262619 70.538315,220.139313 
	C66.067154,226.384888 58.904465,228.695435 52.786049,232.429474 
	C49.967682,234.149506 48.480194,235.764130 48.777344,239.059479 
	C48.391937,239.265244 48.227703,239.445770 48.113510,239.671814 
	C45.467030,234.270355 42.820549,228.868912 40.248260,222.922104 
	C40.508892,222.163589 40.739082,222.021042 41.257553,221.971222 
	C41.502079,221.993347 41.992748,222.012466 41.999840,222.368332 
	C43.241879,224.353271 44.476822,225.982346 45.576054,227.432404 
	C54.415977,224.119553 62.251125,220.396225 66.277588,211.121994 
	C66.813995,212.890091 67.241066,214.297791 67.668144,215.705505 
	C68.255905,215.766037 68.843658,215.826569 69.431419,215.887115 
	C69.955307,213.593643 70.479187,211.300171 71.001686,209.003357 
	C71.000305,209.000000 71.007019,209.002792 71.362473,208.961304 
	C74.294304,207.837845 74.790924,206.214828 72.996468,203.651642 
	C72.975906,202.622238 72.957672,201.937576 73.008850,201.225800 
	C73.078270,201.198715 73.210335,201.129684 73.210335,201.129684 
z"/>
<path fill="#0D7CFC" opacity="1.000000" stroke="none" 
	d="
M137.909424,244.955795 
	C137.030441,246.901917 136.151474,248.848038 134.697220,251.065308 
	C132.666489,250.017395 131.211060,248.698334 129.755630,247.379272 
	C130.009079,247.014511 130.262527,246.649750 130.515991,246.284973 
	C119.959435,244.562683 110.150757,239.628372 99.246758,239.337646 
	C98.806427,239.325897 98.389458,238.437927 97.961609,237.958191 
	C99.163612,235.225784 100.365623,232.493362 101.840263,229.833130 
	C102.774124,230.468323 103.435349,231.031357 104.096558,231.594391 
	C104.537979,230.979889 105.330276,230.377411 105.351791,229.748550 
	C105.405746,228.171631 105.110817,226.582748 105.096504,224.813934 
	C105.819862,224.759476 106.402534,224.889755 106.989624,225.454742 
	C106.994041,227.676987 106.994041,229.464523 106.994041,231.897263 
	C114.061172,233.314987 120.798912,234.613647 127.504219,236.062408 
	C128.662323,236.312622 130.412018,236.996521 130.643265,237.826859 
	C131.762772,241.846848 134.628250,242.255234 137.989380,242.276215 
	C137.953217,243.350708 137.931320,244.153259 137.909424,244.955795 
z"/>
<path fill="#0997FD" opacity="1.000000" stroke="none" 
	d="
M92.017876,249.010590 
	C92.502357,248.435745 92.986832,247.860886 94.092224,247.096863 
	C96.462509,246.887497 98.280365,246.545471 99.949867,246.900711 
	C109.009483,248.828522 117.962479,251.396423 127.098923,252.762817 
	C131.374329,253.402222 132.079041,254.791962 131.126862,258.324127 
	C131.126862,258.324127 131.202759,258.687744 130.834900,259.033630 
	C130.020432,260.380737 129.573807,261.381927 129.127197,262.383118 
	C129.127182,262.383118 129.242996,262.719543 128.890961,262.863342 
	C128.371811,263.006866 128.204697,263.006561 128.037567,263.006226 
	C127.577164,262.380981 127.105110,261.202057 126.658043,261.211456 
	C115.516998,261.445801 105.803864,255.245834 94.982277,254.303345 
	C93.287292,254.155701 91.433556,255.830750 89.336678,256.453125 
	C90.019379,253.832367 91.018623,251.421478 92.017876,249.010590 
z"/>
<path fill="#05DCFC" opacity="1.000000" stroke="none" 
	d="
M30.070198,201.019943 
	C29.785570,200.267624 29.500942,199.515305 29.444088,198.164276 
	C29.782627,197.049789 29.893394,196.533997 30.353500,195.953720 
	C32.986279,194.464890 35.354828,193.159119 37.536404,191.592896 
	C43.019890,187.656174 48.398720,183.573654 53.882244,179.636978 
	C54.447083,179.231461 55.677784,179.048904 56.185505,179.386154 
	C58.875675,181.173157 61.433468,183.159454 64.134308,185.327408 
	C64.477463,186.053955 64.723427,186.529800 64.597191,186.998108 
	C62.368992,186.351883 60.513004,185.713181 58.070751,184.872742 
	C58.910683,187.211960 59.436314,188.675858 60.157600,190.684662 
	C57.751949,190.684662 55.514523,190.014832 54.111340,190.812531 
	C50.185173,193.044464 46.680706,196.003174 42.862656,198.444962 
	C39.690300,200.473846 36.309170,202.176270 32.787231,203.871719 
	C31.726027,202.820068 30.898113,201.919998 30.070198,201.019943 
z"/>
<path fill="#0F65FA" opacity="1.000000" stroke="none" 
	d="
M106.985199,225.020050 
	C106.402534,224.889755 105.819862,224.759476 104.835022,224.804230 
	C104.258362,224.968948 104.083855,224.958633 103.909340,224.948303 
	C105.726326,221.090668 107.543312,217.233047 109.667206,213.559235 
	C109.715286,216.675430 111.291718,217.503937 113.972565,218.263275 
	C124.708702,221.304169 135.333740,224.737305 145.999878,228.025375 
	C143.911957,232.601364 141.824036,237.177353 139.398010,241.902756 
	C139.059921,242.052170 138.930649,241.927216 139.010590,241.525070 
	C139.417938,237.524429 139.745361,233.925919 140.072784,230.327423 
	C139.671143,230.006714 139.269516,229.686005 138.867874,229.365295 
	C135.705109,233.073257 132.577240,235.411682 129.489746,230.148422 
	C129.341370,229.895477 128.519196,230.102264 128.049896,229.963104 
	C123.778145,228.696350 119.556259,227.227066 115.229080,226.198654 
	C113.518326,225.792068 111.578941,226.347427 108.875534,226.520294 
	C108.902374,226.541595 107.943787,225.780823 106.985199,225.020050 
z"/>
<path fill="#0EBDF9" opacity="1.000000" stroke="none" 
	d="
M29.987349,201.331100 
	C30.898113,201.919998 31.726027,202.820068 32.822536,204.232178 
	C33.313820,205.365189 33.492535,206.006302 33.766064,206.604019 
	C36.270058,212.075867 39.745953,212.064194 44.318752,208.505600 
	C50.467308,203.720764 57.337696,199.743057 64.277306,196.151260 
	C67.277466,194.598404 68.766991,193.737457 66.791107,190.530304 
	C66.099342,189.407486 65.571884,188.183441 64.969398,187.005630 
	C64.723427,186.529800 64.477463,186.053955 64.365784,185.318634 
	C64.806580,185.058334 65.112953,185.051743 65.419197,185.039337 
	C68.002083,190.180511 70.584961,195.321701 73.189087,200.796280 
	C73.210335,201.129684 73.078270,201.198715 72.637321,201.091293 
	C68.716820,199.436493 65.896454,199.770035 62.761230,202.509354 
	C58.362473,206.352646 52.702805,208.854309 48.689121,213.000992 
	C46.028725,215.749542 40.902908,216.775116 41.013031,221.949112 
	C40.739082,222.021042 40.508892,222.163589 40.213455,222.550018 
	C36.704472,215.696259 33.304485,208.669266 29.987349,201.331100 
z"/>
<path fill="#0C81FC" opacity="1.000000" stroke="none" 
	d="
M54.992485,249.996201 
	C54.417694,249.492294 53.842911,248.988373 53.118401,247.877747 
	C52.231174,246.575500 51.493671,245.879944 50.756172,245.184387 
	C50.756168,245.184387 50.847321,245.018509 50.876537,244.873077 
	C50.622505,244.442795 50.339256,244.157944 50.056007,243.873108 
	C49.687508,243.155518 49.319012,242.437943 48.972027,241.362015 
	C48.993538,241.003677 49.096951,240.902390 49.452698,240.938263 
	C51.362354,240.979141 53.272316,241.576080 54.411114,240.891022 
	C60.755802,237.074478 67.207428,233.322861 73.018715,228.777191 
	C76.207878,226.282593 81.741219,225.061157 80.984413,219.017120 
	C81.049957,218.956360 81.183281,218.837280 81.183281,218.837280 
	C82.656586,221.367920 84.129898,223.898560 85.429443,226.985825 
	C81.732887,235.245850 72.799026,235.584915 67.324371,240.673828 
	C65.637184,242.242142 63.410252,243.229813 61.430470,244.483353 
	C61.647404,244.877258 61.864342,245.271179 62.081276,245.665100 
	C60.531246,245.944305 58.981216,246.223526 58.026978,246.395432 
	C56.307434,248.375565 61.785114,251.827713 56.007133,251.997162 
	C55.905842,251.672028 55.718697,251.407684 55.223801,250.846054 
	C55.001907,250.487961 54.992485,249.996201 54.992485,249.996201 
z"/>
<path fill="#1919F1" opacity="1.000000" stroke="none" 
	d="
M124.005341,186.992188 
	C123.433815,187.259735 122.862297,187.527267 121.980232,187.851868 
	C124.815277,177.407059 137.888062,172.975021 146.386307,176.252167 
	C153.973434,179.177948 162.004456,187.130829 158.180237,199.154266 
	C156.810211,195.118134 155.879211,191.195206 154.238327,187.595917 
	C153.946976,186.956909 150.615768,188.062790 148.839127,187.673813 
	C143.736389,186.556641 138.539597,185.523483 133.704590,183.628510 
	C129.094360,181.821640 126.366959,183.364624 124.005341,186.992188 
z"/>
<path fill="#0D89FD" opacity="1.000000" stroke="none" 
	d="
M131.433868,258.181061 
	C132.079041,254.791962 131.374329,253.402222 127.098923,252.762817 
	C117.962479,251.396423 109.009483,248.828522 99.949867,246.900711 
	C98.280365,246.545471 96.462509,246.887497 94.327904,246.932648 
	C93.951073,246.248627 93.959473,245.539627 93.964569,244.475464 
	C95.113098,242.198898 96.264915,240.277496 97.689163,238.157135 
	C98.389458,238.437927 98.806427,239.325897 99.246758,239.337646 
	C110.150757,239.628372 119.959435,244.562683 130.515991,246.284973 
	C130.262527,246.649750 130.009079,247.014511 129.755630,247.379272 
	C131.211060,248.698334 132.666489,250.017395 134.304703,251.200897 
	C133.571945,253.389557 132.656418,255.713776 131.433868,258.181061 
z"/>
<path fill="#13E8F7" opacity="1.000000" stroke="none" 
	d="
M65.412979,184.727188 
	C65.112953,185.051743 64.806580,185.058334 64.268600,185.067902 
	C61.433468,183.159454 58.875675,181.173157 56.185505,179.386154 
	C55.677784,179.048904 54.447083,179.231461 53.882244,179.636978 
	C48.398720,183.573654 43.019890,187.656174 37.536404,191.592896 
	C35.354828,193.159119 32.986279,194.464890 30.265953,195.746353 
	C29.669437,195.336014 29.445345,195.143677 29.156790,195.026428 
	C28.806856,186.456451 34.106964,178.902969 42.451473,176.079453 
	C51.155983,173.134140 59.923115,176.317673 65.412979,184.727188 
z"/>
<path fill="#0E73FB" opacity="1.000000" stroke="none" 
	d="
M55.813614,252.200546 
	C61.785114,251.827713 56.307434,248.375565 58.026978,246.395432 
	C58.981216,246.223526 60.531246,245.944305 62.081276,245.665100 
	C61.864342,245.271179 61.647404,244.877258 61.430470,244.483353 
	C63.410252,243.229813 65.637184,242.242142 67.324371,240.673828 
	C72.799026,235.584915 81.732887,235.245850 85.488083,227.322235 
	C86.172134,228.436310 86.623795,229.770584 87.175171,231.726532 
	C87.592537,232.524292 87.910187,232.700394 88.227844,232.876495 
	C88.227844,232.876495 88.221130,232.828033 88.245331,232.820892 
	C88.181023,235.490494 87.623856,237.400589 84.769859,239.032562 
	C77.954079,242.929947 71.604591,247.637329 64.991875,251.901428 
	C64.034737,252.518616 62.664574,252.495255 61.426479,252.781998 
	C61.071129,254.218277 60.692005,255.750656 60.312881,257.283020 
	C59.874634,257.479614 59.436386,257.676208 58.998142,257.872803 
	C57.872128,256.049835 56.746113,254.226883 55.813614,252.200546 
z"/>
<path fill="#1221FC" opacity="1.000000" stroke="none" 
	d="
M124.401688,187.009201 
	C126.366959,183.364624 129.094360,181.821640 133.704590,183.628510 
	C138.539597,185.523483 143.736389,186.556641 148.839127,187.673813 
	C150.615768,188.062790 153.946976,186.956909 154.238327,187.595917 
	C155.879211,191.195206 156.810211,195.118134 157.965759,199.347382 
	C157.982162,199.972855 158.016129,200.181625 157.995148,200.702896 
	C156.604294,199.886078 155.268387,198.756790 153.583298,197.332321 
	C145.604172,195.492325 136.853455,193.474411 128.147888,191.466919 
	C127.090088,190.064651 125.944054,188.545441 124.401688,187.009201 
z"/>
<path fill="#0F56FC" opacity="1.000000" stroke="none" 
	d="
M146.264908,227.809448 
	C135.333740,224.737305 124.708702,221.304169 113.972565,218.263275 
	C111.291718,217.503937 109.715286,216.675430 109.942337,213.341522 
	C110.352699,211.709579 110.794830,210.479172 111.520271,209.083557 
	C111.997284,208.932297 112.190994,208.946274 112.696609,209.358536 
	C115.580879,211.162460 118.003700,213.089279 120.753036,213.878021 
	C129.760956,216.462280 138.887604,218.632721 147.985809,220.983215 
	C148.003006,221.004166 148.039612,221.044174 148.096817,221.207779 
	C148.267990,221.607681 148.455338,221.711899 148.766495,221.722382 
	C148.816956,221.760757 148.889954,221.864426 148.889954,221.864426 
	C148.103287,223.774124 147.316605,225.683823 146.264908,227.809448 
z"/>
<path fill="#0F56FC" opacity="1.000000" stroke="none" 
	d="
M91.744492,248.823227 
	C91.018623,251.421478 90.019379,253.832367 89.017815,256.624603 
	C88.741089,257.200134 88.466690,257.394348 87.697342,257.755005 
	C83.389847,255.345810 82.796173,259.795166 80.663727,260.899872 
	C74.636993,264.021881 68.582161,267.089661 62.538391,270.178802 
	C62.156002,269.598602 61.773613,269.018402 61.367462,268.175110 
	C65.178131,258.265869 75.035889,256.282196 82.821938,250.235107 
	C82.899338,249.716476 83.134682,248.139603 83.395264,246.393631 
	C86.254997,247.187622 88.863060,247.911743 91.744492,248.823227 
z"/>
<path fill="#0F4AFC" opacity="1.000000" stroke="none" 
	d="
M62.605965,270.508301 
	C68.582161,267.089661 74.636993,264.021881 80.663727,260.899872 
	C82.796173,259.795166 83.389847,255.345810 87.556435,257.966980 
	C86.648499,260.783417 85.386513,263.554321 84.020020,266.663940 
	C83.915512,267.002686 83.726562,266.950378 83.399124,267.029602 
	C82.402573,268.427399 81.733467,269.745941 81.085205,271.075745 
	C81.106049,271.087006 81.133850,271.126007 80.750519,271.092041 
	C79.311317,271.336761 77.907616,271.285797 77.268890,271.959778 
	C75.736115,273.577179 74.881828,275.615479 71.821175,274.846527 
	C69.679192,274.308350 67.252296,274.904144 64.952652,274.993500 
	C64.952652,274.993500 64.748207,274.820862 64.762253,274.389709 
	C64.514534,273.670105 64.252762,273.381683 63.990982,273.093231 
	C63.551834,272.341431 63.112682,271.589600 62.605965,270.508301 
z"/>
<path fill="#DADADA" opacity="1.000000" stroke="none" 
	d="
M152.174957,238.058533 
	C149.889923,235.074280 149.946716,228.225449 152.511627,227.404190 
	C155.670410,226.392776 159.608734,226.407623 162.729507,227.436646 
	C163.848022,227.805466 164.088409,232.490631 163.720062,235.061111 
	C163.517105,236.477386 161.375488,238.345016 159.828033,238.667908 
	C157.530533,239.147293 154.984863,238.437347 152.174957,238.058533 
z"/>
<path fill="#123EFB" opacity="1.000000" stroke="none" 
	d="
M64.975929,275.366211 
	C67.252296,274.904144 69.679192,274.308350 71.821175,274.846527 
	C74.881828,275.615479 75.736115,273.577179 77.268890,271.959778 
	C77.907616,271.285797 79.311317,271.336761 80.736893,271.463837 
	C80.632835,273.553345 80.159073,275.237091 79.473076,277.151733 
	C78.889091,278.595703 78.517326,279.808838 78.145569,281.021973 
	C77.807487,281.450775 77.469406,281.879578 76.729126,282.776550 
	C75.113441,285.535828 73.899956,287.826935 72.686478,290.118042 
	C70.124054,285.325012 67.561630,280.531952 64.975929,275.366211 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M203.083237,299.032562 
	C202.412506,294.702179 205.769897,291.934570 209.853485,293.570435 
	C212.345261,294.568634 214.729401,295.835480 217.585999,297.439301 
	C218.010101,300.877228 218.010101,303.857513 218.010101,307.681580 
	C220.529892,305.112061 221.853149,303.762695 223.176392,302.413330 
	C223.777069,303.938751 224.377731,305.464203 224.978409,306.989624 
	C217.999283,311.128174 216.827408,310.448029 215.918549,302.146393 
	C215.791199,300.983246 214.759888,299.647766 213.758575,298.947296 
	C213.095306,298.483276 210.846985,298.930115 210.831329,299.105347 
	C210.679428,300.804352 210.849945,302.532166 210.549881,304.597229 
	C202.844482,305.203735 207.233002,299.991028 206.442947,296.431549 
	C205.126556,297.450653 204.104904,298.241608 203.083237,299.032562 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M239.953796,298.649231 
	C241.157196,297.729248 241.968613,296.803619 243.628128,294.910492 
	C243.628128,299.481934 243.628128,303.026215 243.628128,306.718048 
	C241.146408,306.523285 238.284180,306.298645 234.450577,305.997772 
	C234.369720,304.961090 234.008194,302.940521 234.119614,300.946411 
	C234.212723,299.280060 234.859360,297.644653 235.260864,295.995544 
	C236.694519,296.878235 238.128159,297.760895 239.953796,298.649231 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M190.080566,301.196503 
	C190.080185,299.375519 190.080185,297.927521 190.080185,296.150970 
	C192.309494,296.150970 194.369370,296.150970 196.550934,296.150970 
	C196.550934,299.755402 196.550934,303.164429 196.550934,306.916504 
	C194.716034,306.916504 192.651779,306.916504 190.080933,306.916504 
	C190.080933,305.049042 190.080933,303.309265 190.080566,301.196503 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M282.305359,297.092834 
	C280.257416,295.257202 281.001099,294.293457 283.348999,294.046173 
	C285.632782,293.805634 287.935822,293.747955 289.867401,293.631836 
	C291.147644,295.428192 292.195984,297.257416 293.588013,298.770447 
	C295.803101,301.178070 295.472534,302.778015 292.021362,303.657959 
	C291.536713,303.249817 291.518524,303.067261 291.423248,302.944641 
	C291.331818,302.826996 291.134064,302.674255 291.028107,302.703064 
	C289.018036,303.249176 287.014587,303.819916 285.009796,304.385529 
	C284.708099,303.152802 283.754608,301.331757 284.232300,300.802521 
	C285.449036,299.454498 287.316986,298.701447 288.890350,297.658966 
	C289.085907,297.529358 289.075745,297.089233 289.468414,295.723114 
	C286.773071,296.238525 284.539215,296.665680 282.305359,297.092834 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M250.802658,303.035095 
	C246.653534,300.386719 250.267792,297.590057 251.288361,295.603363 
	C252.054871,294.111237 255.165146,293.282654 257.260498,293.226715 
	C258.421387,293.195770 259.640869,295.358856 260.475952,297.082031 
	C259.691925,298.803070 259.366913,300.025513 258.813568,301.133942 
	C258.159515,302.444031 257.308289,303.655640 256.542542,304.909973 
	C255.698822,303.610504 254.637360,302.396027 254.085800,300.982544 
	C253.700043,299.993988 254.011383,298.733398 254.011383,297.594299 
	C253.654251,297.487732 253.297119,297.381165 252.939972,297.274597 
	C252.227539,299.194763 251.515106,301.114929 250.802658,303.035095 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M225.273224,307.032684 
	C224.377731,305.464203 223.777069,303.938751 223.176392,302.413330 
	C221.853149,303.762695 220.529892,305.112061 218.010101,307.681580 
	C218.010101,303.857513 218.010101,300.877228 217.959427,297.449615 
	C219.549271,297.708466 221.189774,298.414612 223.270493,299.310242 
	C223.873840,298.464569 224.828766,297.126129 225.783676,295.787689 
	C226.798645,299.714539 228.979889,303.513885 225.273224,307.032684 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M339.415985,296.108154 
	C342.024811,295.971558 344.513184,295.594360 343.224518,299.276825 
	C343.028595,299.836731 343.223206,300.568024 343.345428,301.199097 
	C344.479980,307.056610 343.061005,308.253479 336.574554,306.336273 
	C337.617249,305.236267 338.500549,304.304382 339.447968,303.304871 
	C337.929688,301.039124 334.685425,298.807434 339.415985,296.108154 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M282.194580,297.471497 
	C284.539215,296.665680 286.773071,296.238525 289.468414,295.723114 
	C289.075745,297.089233 289.085907,297.529358 288.890350,297.658966 
	C287.316986,298.701447 285.449036,299.454498 284.232300,300.802521 
	C283.754608,301.331757 284.708099,303.152802 285.009796,304.385529 
	C287.014587,303.819916 289.018036,303.249176 291.028107,302.703064 
	C291.134064,302.674255 291.331818,302.826996 291.423248,302.944641 
	C291.518524,303.067261 291.536713,303.249817 291.639648,303.670837 
	C290.020233,307.342957 287.158142,308.327942 283.969696,306.776978 
	C279.878448,304.786926 280.924805,301.216492 282.194580,297.471497 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M307.035828,300.917969 
	C306.549347,303.051270 306.710480,306.036987 305.374390,307.052002 
	C304.277649,307.885223 299.927734,306.841705 299.602539,305.813263 
	C298.779633,303.210968 299.410278,300.148987 300.074921,297.048340 
	C300.772675,298.394104 300.563843,300.054596 301.022766,301.503601 
	C301.427795,302.782654 302.577820,303.825714 303.398865,304.972992 
	C303.798553,304.689911 304.198242,304.406830 304.597931,304.123749 
	C304.055511,303.254913 303.513092,302.386047 302.596588,300.917969 
	C304.499481,300.917969 305.767670,300.917969 307.035828,300.917969 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M327.675903,303.373840 
	C328.415558,304.355469 329.409241,305.118622 331.457489,306.691711 
	C328.539734,306.919159 326.626526,307.068298 324.281677,307.251099 
	C324.281677,303.462067 324.281677,300.088135 324.281677,296.318237 
	C326.247803,296.318237 328.120422,296.318237 330.411896,296.318237 
	C329.556732,298.673981 328.743317,300.914703 327.675903,303.373840 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M250.924347,303.319214 
	C251.515106,301.114929 252.227539,299.194763 252.939972,297.274597 
	C253.297119,297.381165 253.654251,297.487732 254.011383,297.594299 
	C254.011383,298.733398 253.700043,299.993988 254.085800,300.982544 
	C254.637360,302.396027 255.698822,303.610504 256.542542,304.909973 
	C257.308289,303.655640 258.159515,302.444031 258.813568,301.133942 
	C259.366913,300.025513 259.691925,298.803070 260.373810,297.491058 
	C260.629669,297.351013 260.675110,297.314148 260.761261,297.649963 
	C261.078125,299.923981 261.308838,301.862213 261.539551,303.800415 
	C260.554382,304.871307 259.569244,305.942169 257.900208,307.082153 
	C255.500290,306.717743 253.784225,306.284210 252.068146,305.850677 
	C251.727448,305.101562 251.386734,304.352478 250.924347,303.319214 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M261.943542,303.853455 
	C261.308838,301.862213 261.078125,299.923981 260.782959,297.630371 
	C262.147308,297.439117 263.576080,297.603302 263.987366,297.650543 
	C266.281891,295.888733 267.917908,294.632538 269.553925,293.376312 
	C270.012299,294.085388 270.470703,294.794464 270.505371,295.831177 
	C269.448883,297.035889 268.395966,297.855194 268.265991,298.801300 
	C267.940399,301.171692 268.032898,303.599518 267.957703,306.004303 
	C266.087646,305.305023 264.217560,304.605774 261.943542,303.853455 
z"/>
<path fill="#0EBDF9" opacity="1.000000" stroke="none" 
	d="
M72.713333,290.466278 
	C73.899956,287.826935 75.113441,285.535828 76.602142,283.021057 
	C76.585732,285.582062 76.294113,288.366760 76.019623,291.577850 
	C77.022896,295.717316 78.009041,299.430420 79.008057,303.513306 
	C76.927353,299.526886 74.833778,295.170715 72.713333,290.466278 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M307.037537,300.517548 
	C305.767670,300.917969 304.499481,300.917969 302.596588,300.917969 
	C303.513092,302.386047 304.055511,303.254913 304.597931,304.123749 
	C304.198242,304.406830 303.798553,304.689911 303.398865,304.972992 
	C302.577820,303.825714 301.427795,302.782654 301.022766,301.503601 
	C300.563843,300.054596 300.772675,298.394104 300.322388,296.747589 
	C304.155060,293.437286 306.058838,294.363556 307.037537,300.517548 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M268.094604,306.226135 
	C268.032898,303.599518 267.940399,301.171692 268.265991,298.801300 
	C268.395966,297.855194 269.448883,297.035889 270.472778,296.206177 
	C270.905823,296.640076 270.947723,297.026581 270.987762,298.072418 
	C271.051544,299.500244 271.117249,300.268738 271.112915,301.403107 
	C271.087372,302.509155 271.131805,303.249390 271.160614,304.231903 
	C271.145020,304.474213 271.516937,304.786560 271.516937,304.786560 
	C271.516937,304.786560 272.002075,304.762604 272.367279,304.845367 
	C273.478424,304.990356 274.224335,305.052582 274.994598,305.428650 
	C275.011658,306.161285 275.004395,306.580078 274.997131,306.998901 
	C274.997131,306.998901 275.000000,307.000000 274.569763,307.022736 
	C272.419159,307.088013 270.698761,307.130554 268.978363,307.173096 
	C268.729401,306.931366 268.480469,306.689667 268.094604,306.226135 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M203.061020,299.365814 
	C204.104904,298.241608 205.126556,297.450653 206.442947,296.431549 
	C207.233002,299.991028 202.844482,305.203735 210.579468,304.976929 
	C210.987625,305.011169 210.998917,305.000580 210.960419,305.396545 
	C209.286163,306.258667 207.650436,306.724762 206.014709,307.190857 
	C205.343872,306.623901 204.673019,306.056946 203.796936,305.023987 
	C203.486221,304.273499 203.285858,304.096008 202.990601,304.025604 
	C202.985229,303.850037 202.979858,303.674500 202.972946,302.917175 
	C202.910995,301.894135 202.850601,301.452881 202.790207,301.011658 
	C202.873077,300.574127 202.955933,300.136597 203.061020,299.365814 
z"/>
<path fill="#68B261" opacity="1.000000" stroke="none" 
	d="
M316.999817,301.960815 
	C316.753082,303.963959 316.504242,305.520264 316.255371,307.076599 
	C315.737122,307.016052 315.218842,306.955536 314.700562,306.894989 
	C314.700562,303.333435 314.700562,299.771881 314.700562,296.210327 
	C315.204681,296.140747 315.708801,296.071198 316.212921,296.001617 
	C316.474518,297.839081 316.736115,299.676544 316.999817,301.960815 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M275.384521,306.924133 
	C275.004395,306.580078 275.011658,306.161285 275.003174,305.050049 
	C273.719269,303.250854 272.451080,302.144043 271.182922,301.037262 
	C271.117249,300.268738 271.051544,299.500244 271.069885,298.352661 
	C272.898254,298.472656 274.642639,298.971741 277.321167,299.738068 
	C276.809967,302.084656 276.290955,304.467010 275.384521,306.924133 
z"/>
<path fill="#0D6EFB" opacity="1.000000" stroke="none" 
	d="
M103.593033,225.104828 
	C104.083855,224.958633 104.258362,224.968948 104.694336,224.988983 
	C105.110817,226.582748 105.405746,228.171631 105.351791,229.748550 
	C105.330276,230.377411 104.537979,230.979889 104.096558,231.594391 
	C103.435349,231.031357 102.774124,230.468323 102.067062,229.599548 
	C102.439735,227.949646 102.858231,226.605484 103.593033,225.104828 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M206.214722,307.428650 
	C207.650436,306.724762 209.286163,306.258667 210.955109,305.402222 
	C211.588348,305.744263 212.188370,306.476593 212.788391,307.208954 
	C211.630386,307.942566 210.535934,309.073456 209.292389,309.272400 
	C208.440613,309.408691 207.381271,308.247589 206.214722,307.428650 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M269.070801,307.525146 
	C270.698761,307.130554 272.419159,307.088013 274.569275,307.021423 
	C273.526520,310.426575 271.358154,309.239990 269.070801,307.525146 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M252.052353,306.238098 
	C253.784225,306.284210 255.500290,306.717743 257.603027,307.209656 
	C255.775436,309.183960 253.587387,310.856720 252.052353,306.238098 
z"/>
<path fill="#09D9FB" opacity="1.000000" stroke="none" 
	d="
M97.395432,311.010986 
	C99.406387,309.967499 101.799835,308.986542 104.565933,308.009827 
	C102.551697,309.033875 100.164810,310.053680 97.395432,311.010986 
z"/>
<path fill="#123EFB" opacity="1.000000" stroke="none" 
	d="
M151.089417,217.082260 
	C150.483109,218.499176 149.876801,219.916077 149.080231,221.598694 
	C148.889954,221.864426 148.816956,221.760757 148.726318,221.560699 
	C148.474686,221.184357 148.276001,221.078873 148.039612,221.044174 
	C148.039612,221.044174 148.003006,221.004166 148.031464,220.644257 
	C149.017044,219.164581 149.974182,218.044785 150.975281,216.959854 
	C151.019226,216.994720 151.089401,217.082260 151.089417,217.082260 
z"/>
<path fill="#1331F9" opacity="1.000000" stroke="none" 
	d="
M152.997192,212.997269 
	C152.567749,214.260773 152.107941,215.494308 151.368759,216.905045 
	C151.089401,217.082260 151.019226,216.994720 151.000015,216.567963 
	C150.988632,215.094421 150.996445,214.047623 151.321960,212.997025 
	C152.061768,212.964584 152.483902,212.935944 152.906036,212.907318 
	C152.906036,212.907318 152.966797,212.967285 152.997192,212.997269 
z"/>
<path fill="#0E73FB" opacity="1.000000" stroke="none" 
	d="
M50.789570,245.504562 
	C51.493671,245.879944 52.231174,246.575500 52.950500,247.638947 
	C52.932331,248.006851 52.528969,247.997528 52.327400,247.990128 
	C51.691540,247.263397 51.257256,246.544067 50.789570,245.504562 
z"/>
<path fill="#09D9FB" opacity="1.000000" stroke="none" 
	d="
M105.321548,307.912781 
	C105.522720,307.336029 106.028198,306.728851 106.871216,306.158966 
	C106.681114,306.758301 106.153488,307.320343 105.321548,307.912781 
z"/>
<path fill="#0D89FD" opacity="1.000000" stroke="none" 
	d="
M129.441467,262.240601 
	C129.573807,261.381927 130.020432,260.380737 130.737701,259.191895 
	C130.590805,260.035522 130.173264,261.066833 129.441467,262.240601 
z"/>
<path fill="#0F65FA" opacity="1.000000" stroke="none" 
	d="
M138.152603,244.752182 
	C137.931320,244.153259 137.953217,243.350708 138.221558,242.262421 
	C138.468018,241.976685 138.930649,241.927216 138.930649,241.927216 
	C138.930649,241.927216 139.059921,242.052170 139.126190,242.112946 
	C138.926880,242.965347 138.661331,243.756958 138.152603,244.752182 
z"/>
<path fill="#0EBDF9" opacity="1.000000" stroke="none" 
	d="
M29.079998,195.390564 
	C29.445345,195.143677 29.669437,195.336014 29.916613,195.810837 
	C29.893394,196.533997 29.782627,197.049789 29.483894,197.755035 
	C29.198353,197.214554 29.100779,196.484634 29.079998,195.390564 
z"/>
<path fill="#0D89FD" opacity="1.000000" stroke="none" 
	d="
M128.025208,263.358612 
	C128.204697,263.006561 128.371811,263.006866 128.789673,263.009583 
	C128.860229,263.825287 128.680054,264.638519 128.236267,265.635834 
	C127.986053,265.116913 127.999458,264.413971 128.025208,263.358612 
z"/>
<path fill="#0E73FB" opacity="1.000000" stroke="none" 
	d="
M50.036991,244.159912 
	C50.339256,244.157944 50.622505,244.442795 50.854332,244.965057 
	C50.541267,244.950577 50.279621,244.698639 50.036991,244.159912 
z"/>
<path fill="#0C81FC" opacity="1.000000" stroke="none" 
	d="
M79.989532,218.998779 
	C80.006889,218.476685 80.024239,217.954605 80.075058,217.041046 
	C80.467209,217.157883 80.825905,217.666168 81.183945,218.505859 
	C81.183281,218.837280 81.049957,218.956360 80.752792,218.990906 
	C80.455635,219.025452 79.989532,218.998779 79.989532,218.998779 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M202.599457,301.170898 
	C202.850601,301.452881 202.910995,301.894135 202.899551,302.664185 
	C202.688049,302.438690 202.548386,301.884430 202.599457,301.170898 
z"/>
<path fill="#0C81FC" opacity="1.000000" stroke="none" 
	d="
M48.096062,239.976288 
	C48.227703,239.445770 48.391937,239.265244 48.800987,239.379044 
	C49.029488,240.052704 49.063221,240.477539 49.096951,240.902390 
	C49.096951,240.902390 48.993538,241.003677 48.941223,241.053711 
	C48.618813,240.829422 48.348709,240.555099 48.096062,239.976288 
z"/>
<path fill="#0AA7FD" opacity="1.000000" stroke="none" 
	d="
M121.086090,280.077484 
	C120.975449,280.685883 120.816856,281.253601 120.345329,281.925079 
	C120.032402,282.028870 119.919930,281.919800 119.966713,281.634399 
	C120.321877,280.885925 120.630257,280.422852 120.938644,279.959808 
	C120.938644,279.959808 121.038139,280.036774 121.086090,280.077484 
z"/>
<path fill="#0F65FA" opacity="1.000000" stroke="none" 
	d="
M88.151810,232.601166 
	C87.910187,232.700394 87.592537,232.524292 87.171616,232.088913 
	C87.404144,231.995041 87.739960,232.160446 88.151810,232.601166 
z"/>
<path fill="#09D9FB" opacity="1.000000" stroke="none" 
	d="
M107.430038,306.243652 
	C107.366241,306.018433 107.551758,305.793213 107.863083,305.710327 
	C107.988884,305.852661 107.679359,306.243591 107.430038,306.243652 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M203.028503,304.216827 
	C203.285858,304.096008 203.486221,304.273499 203.587067,304.765503 
	C203.340805,304.848236 203.168793,304.659943 203.028503,304.216827 
z"/>
<path fill="#0F56FC" opacity="1.000000" stroke="none" 
	d="
M64.011993,273.397095 
	C64.252762,273.381683 64.514534,273.670105 64.803513,274.262451 
	C64.564812,274.277863 64.298904,273.989410 64.011993,273.397095 
z"/>
<path fill="#0AA7FD" opacity="1.000000" stroke="none" 
	d="
M119.924950,282.863525 
	C119.756104,283.065979 119.388466,283.045959 119.196793,282.789551 
	C119.002716,282.361511 119.000320,282.189880 119.229080,281.999878 
	C119.460243,281.981445 119.919930,281.919800 119.919930,281.919800 
	C119.919930,281.919800 120.032402,282.028870 120.089905,282.082703 
	C120.129532,282.311371 120.111664,282.486237 119.924950,282.863525 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M393.668701,255.256470 
	C400.727753,254.863892 401.589874,255.907990 400.343750,262.496307 
	C399.700928,265.894958 399.452759,269.387573 398.572632,272.717834 
	C396.927246,278.943970 389.572815,282.394714 383.887939,279.600159 
	C382.923706,279.126160 382.130951,277.236511 382.157166,276.020233 
	C382.227936,272.736572 382.812073,269.466309 383.139954,266.185455 
	C383.760132,259.980011 387.970490,257.174011 393.668701,255.256470 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M386.000610,249.000610 
	C385.885651,249.438583 385.770111,249.875961 385.654602,250.313339 
	C385.629517,249.955612 385.604462,249.597885 385.789093,249.119690 
	C385.998779,248.999237 386.000000,249.000000 386.000610,249.000610 
z"/>
<path fill="#F99455" opacity="1.000000" stroke="none" 
	d="
M292.767883,230.120026 
	C295.577881,230.127213 298.039612,229.755768 300.388397,230.117477 
	C301.947601,230.357574 303.345306,231.646500 304.815308,232.465942 
	C303.458893,233.310242 302.188293,234.656921 300.730164,234.906036 
	C296.084412,235.699799 291.368927,236.085388 285.880341,235.663345 
	C288.044067,233.793243 290.207825,231.923141 292.767883,230.120026 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M244.868134,253.962341 
	C246.863998,254.981018 249.799042,256.168182 249.699081,256.983032 
	C249.458969,258.940399 248.137466,261.918427 246.639923,262.347565 
	C242.488312,263.537231 238.024033,263.635895 233.493698,264.188782 
	C234.773392,255.645233 236.655212,253.996628 244.868134,253.962341 
z"/>
<path fill="#07B9FD" opacity="1.000000" stroke="none" 
	d="
M119.966713,281.634399 
	C119.919930,281.919800 119.460243,281.981445 118.914642,281.861298 
	C111.388519,280.072998 104.476418,277.957520 97.406136,276.876221 
	C91.593872,275.987274 85.515228,268.751892 79.685303,276.920868 
	C80.159073,275.237091 80.632835,273.553345 81.120224,271.497803 
	C81.133850,271.126007 81.106049,271.087006 81.373024,270.938538 
	C82.335518,269.510162 83.031036,268.230286 83.726562,266.950378 
	C83.726562,266.950378 83.915512,267.002686 84.012535,267.022095 
	C88.089340,262.022400 93.227379,265.633453 97.686584,265.634583 
	C101.630035,265.635559 105.674034,267.550293 109.472542,269.109894 
	C111.312790,269.865509 112.692169,271.743561 114.018875,272.888000 
	C115.039604,271.789185 116.164299,269.506897 117.529572,269.352570 
	C119.740250,269.102600 122.019653,275.959412 121.001099,279.586792 
	C120.630257,280.422852 120.321877,280.885925 119.966713,281.634399 
z"/>
<path fill="#06CAFD" opacity="1.000000" stroke="none" 
	d="
M79.473083,277.151733 
	C85.515228,268.751892 91.593872,275.987274 97.406136,276.876221 
	C104.476418,277.957520 111.388519,280.072998 118.683487,281.879700 
	C119.000320,282.189880 119.002716,282.361511 119.012703,282.790771 
	C118.357185,283.950531 117.590347,284.794800 117.049515,285.765076 
	C115.044670,289.361755 113.598503,291.704132 108.118484,289.526184 
	C98.744537,285.800507 88.675156,283.824615 78.521698,281.067932 
	C78.517326,279.808838 78.889091,278.595703 79.473083,277.151733 
z"/>
<path fill="#0E73FB" opacity="1.000000" stroke="none" 
	d="
M54.826229,250.085968 
	C54.992485,249.996201 55.001907,250.487961 55.004799,250.733612 
	C54.891785,250.711411 54.775879,250.443573 54.826229,250.085968 
z"/>
<path fill="#0F4AFC" opacity="1.000000" stroke="none" 
	d="
M151.004272,213.000824 
	C150.996445,214.047623 150.988632,215.094421 150.956070,216.533096 
	C149.974182,218.044785 149.017044,219.164581 148.014252,220.623306 
	C138.887604,218.632721 129.760956,216.462280 120.753036,213.878021 
	C118.003700,213.089279 115.580879,211.162460 112.987457,209.375168 
	C113.566788,207.854462 114.167183,206.715439 115.306503,205.286484 
	C119.635689,203.949814 123.153885,204.073792 126.510941,206.599152 
	C127.740891,207.524399 129.618622,207.613419 131.217239,208.019379 
	C137.809494,209.693344 144.408051,211.342529 151.004272,213.000824 
z"/>
<path fill="#123EFB" opacity="1.000000" stroke="none" 
	d="
M151.321960,212.997009 
	C144.408051,211.342529 137.809494,209.693344 131.217239,208.019379 
	C129.618622,207.613419 127.740891,207.524399 126.510941,206.599152 
	C123.153885,204.073792 119.635689,203.949814 115.444328,204.995667 
	C115.849373,203.951263 116.754501,202.969009 117.444603,201.853714 
	C120.482643,196.943771 122.056221,196.859650 127.533447,198.877335 
	C133.364914,201.025513 139.461884,202.697830 145.585678,203.735611 
	C151.177368,204.683182 152.913010,206.020447 152.939865,212.502472 
	C152.483902,212.935944 152.061768,212.964584 151.321960,212.997009 
z"/>
<path fill="#0A8FFD" opacity="1.000000" stroke="none" 
	d="
M49.452698,240.938263 
	C49.063221,240.477539 49.029488,240.052704 48.972111,239.308289 
	C48.480194,235.764130 49.967682,234.149506 52.786049,232.429474 
	C58.904465,228.695435 66.067154,226.384888 70.538315,220.139313 
	C71.165939,219.262619 73.173027,219.244339 74.570938,219.082825 
	C76.115982,218.904282 77.694809,219.018143 79.624229,219.001724 
	C79.989532,218.998779 80.455635,219.025452 80.687256,219.051666 
	C81.741219,225.061157 76.207878,226.282593 73.018715,228.777191 
	C67.207428,233.322861 60.755802,237.074478 54.411114,240.891022 
	C53.272316,241.576080 51.362354,240.979141 49.452698,240.938263 
z"/>
<path fill="#06B2FE" opacity="1.000000" stroke="none" 
	d="
M41.257553,221.971222 
	C40.902908,216.775116 46.028725,215.749542 48.689121,213.000992 
	C52.702805,208.854309 58.362473,206.352646 62.761230,202.509354 
	C65.896454,199.770035 68.716820,199.436493 72.567902,201.118378 
	C72.957672,201.937576 72.975906,202.622238 72.634537,203.614899 
	C70.666672,203.831299 69.058411,203.739700 66.841408,203.613434 
	C68.794083,206.139755 69.900551,207.571274 71.007019,209.002792 
	C71.007019,209.002792 71.000305,209.000000 70.583069,209.001221 
	C66.566208,210.380035 62.250111,210.934799 59.520576,213.311584 
	C54.314701,217.844696 48.424110,220.305862 41.992748,222.012466 
	C41.992748,222.012466 41.502079,221.993347 41.257553,221.971222 
z"/>
<path fill="#0AA7FD" opacity="1.000000" stroke="none" 
	d="
M41.999840,222.368332 
	C48.424110,220.305862 54.314701,217.844696 59.520576,213.311584 
	C62.250111,210.934799 66.566208,210.380035 70.584442,209.004578 
	C70.479187,211.300171 69.955307,213.593643 69.431419,215.887115 
	C68.843658,215.826569 68.255905,215.766037 67.668144,215.705505 
	C67.241066,214.297791 66.813995,212.890091 66.277588,211.121994 
	C62.251125,220.396225 54.415977,224.119553 45.576054,227.432404 
	C44.476822,225.982346 43.241879,224.353271 41.999840,222.368332 
z"/>
<path fill="#0AA7FD" opacity="1.000000" stroke="none" 
	d="
M71.362473,208.961304 
	C69.900551,207.571274 68.794083,206.139755 66.841408,203.613434 
	C69.058411,203.739700 70.666672,203.831299 72.636856,203.959625 
	C74.790924,206.214828 74.294304,207.837845 71.362473,208.961304 
z"/>
<path fill="#0D6EFB" opacity="1.000000" stroke="none" 
	d="
M139.010590,241.525070 
	C138.930649,241.927216 138.468018,241.976685 138.235840,241.990479 
	C134.628250,242.255234 131.762772,241.846848 130.643265,237.826859 
	C130.412018,236.996521 128.662323,236.312622 127.504219,236.062408 
	C120.798912,234.613647 114.061172,233.314987 106.994041,231.897263 
	C106.994041,229.464523 106.994041,227.676987 106.989624,225.454742 
	C107.943787,225.780823 108.902374,226.541595 108.875534,226.520294 
	C111.578941,226.347427 113.518326,225.792068 115.229080,226.198654 
	C119.556259,227.227066 123.778145,228.696350 128.049896,229.963104 
	C128.519196,230.102264 129.341370,229.895477 129.489746,230.148422 
	C132.577240,235.411682 135.705109,233.073257 138.867874,229.365295 
	C139.269516,229.686005 139.671143,230.006714 140.072784,230.327423 
	C139.745361,233.925919 139.417938,237.524429 139.010590,241.525070 
z"/>
<path fill="#06CAFD" opacity="1.000000" stroke="none" 
	d="
M64.597191,186.998108 
	C65.571884,188.183441 66.099342,189.407486 66.791107,190.530304 
	C68.766991,193.737457 67.277466,194.598404 64.277306,196.151260 
	C57.337696,199.743057 50.467308,203.720764 44.318752,208.505600 
	C39.745953,212.064194 36.270058,212.075867 33.766064,206.604019 
	C33.492535,206.006302 33.313820,205.365189 33.055828,204.383759 
	C36.309170,202.176270 39.690300,200.473846 42.862656,198.444962 
	C46.680706,196.003174 50.185173,193.044464 54.111340,190.812531 
	C55.514523,190.014832 57.751949,190.684662 60.157600,190.684662 
	C59.436314,188.675858 58.910683,187.211960 58.070751,184.872742 
	C60.513004,185.713181 62.368992,186.351883 64.597191,186.998108 
z"/>
<path fill="#0F4AFC" opacity="1.000000" stroke="none" 
	d="
M148.096817,221.207779 
	C148.276001,221.078873 148.474686,221.184357 148.675842,221.522324 
	C148.455338,221.711899 148.267990,221.607681 148.096817,221.207779 
z"/>
<path fill="#123EFB" opacity="1.000000" stroke="none" 
	d="
M83.399124,267.029602 
	C83.031036,268.230286 82.335518,269.510162 81.352188,270.927307 
	C81.733467,269.745941 82.402573,268.427399 83.399124,267.029602 
z"/>
<path fill="#FFFFFE" opacity="1.000000" stroke="none" 
	d="
M271.112915,301.403107 
	C272.451080,302.144043 273.719269,303.250854 274.978851,304.736206 
	C274.224335,305.052582 273.478424,304.990356 272.268799,304.678467 
	C271.637543,304.222107 271.427917,304.075714 271.176208,303.989594 
	C271.131805,303.249390 271.087372,302.509155 271.112915,301.403107 
z"/>
<path fill="#ECFDEA" opacity="1.000000" stroke="none" 
	d="
M271.160614,304.231903 
	C271.427917,304.075714 271.637543,304.222107 271.903564,304.595703 
	C272.002075,304.762604 271.516937,304.786560 271.516937,304.786560 
	C271.516937,304.786560 271.145020,304.474213 271.160614,304.231903 
z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
